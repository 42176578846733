import React from 'react';

function Footer() {
  return (
    <div className='footer'>
      <div className='footerText'>Boznánszkykés - Tartalomkezelő felület</div>
    </div>
  )
}

export default Footer;
